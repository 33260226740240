import React from 'react';
import SlickSlider from 'react-slick';
import { useSelector } from 'react-redux';

import ProductCard from './ProductCard';

export default function FeaturedProduct() {
  const sliderSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { featuredResult: featuredProducts } = useSelector((state) => state.product);

  return (
    <div>
      <h2 className="text-2xl font-bold uppercase">Produk Pilihan BSTORE</h2>
      <div className="mt-3 -mx-3">
        <SlickSlider {...sliderSettings} infinite={featuredProducts.length >= 5}>
          {featuredProducts.map((product) => (
            <div key={product.id} className="px-2 py-4">
              <ProductCard product={product} />
            </div>
          ))}
        </SlickSlider>
      </div>
    </div>
  );
}
